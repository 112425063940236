import {
  createEffect,
  createMemo,
  For,
  onCleanup,
  Setter,
  Show,
} from "solid-js";
import { editAICircularIcon } from "~/assets/assets";
import { createSignal } from "solid-js";
import { GiftCardDetails, GiftCardSelection } from "~/server/types/pluto";

export function GiftCardBack({
  giftDetails,
  isEditable,
  onClick,
  onUpdate,
}: {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  onClick?: () => void;
  onUpdate?: Setter<GiftCardSelection>;
}) {
  // const latestTitleIndex = createMemo(() => {
  //   return Number(giftDetails().content.selection.titleId) - 1;
  // });

  // const latestActivityIndices = createMemo(() => {
  //   return Array(giftDetails()?.content.text.activitySlots.length)
  //     .fill(0)
  //     .map((_, index) => {
  //       return (
  //         Number(giftDetails()?.content.selection.activities[index + 1] || 1) -
  //         1
  //       );
  //     });
  // });

  const [titleIndex, setTitleIndex] = createSignal(
    Number(giftDetails().content.selection.titleId) - 1
  );

  const activitySlots = giftDetails()?.content.text.activitySlots || [];

  const activitySelections = giftDetails()?.content.selection.activities || {};

  const initialActivityIndices = Array(activitySlots.length)
    .fill(0)
    .map((_, index) => {
      return Number(activitySelections[index + 1] || 1) - 1;
    });

  const [activityIndices, setActivityIndices] = createSignal(
    initialActivityIndices
  );

  const [titleFade, setTitleFade] = createSignal(false);
  const [activityFades, setActivityFades] = createSignal(
    Array(activitySlots.length).fill(false)
  );
  const [showDotsIndex, setShowDotsIndex] = createSignal<number | null>(null);

  const rotateTitle = () => {
    setTitleFade(true);
    setTimeout(() => {
      const titles = giftDetails()?.content.text.titles;
      if (titles) {
        const nextIndex = (titleIndex() + 1) % titles.length;
        setTitleIndex(nextIndex);

        const newTitleId = titles[nextIndex].id;
        onUpdate?.((prev) => ({
          ...prev,
          titleId: newTitleId,
        }));
      }
      setTitleFade(false);
    }, 250);
  };

  const rotateActivity = (index: number) => {
    setActivityFades((prevFades) => {
      const newFades = [...prevFades];
      newFades[index] = true;
      return newFades;
    });

    setShowDotsIndex(index);
    const dotsTimeout = setTimeout(() => setShowDotsIndex(null), 2000);

    setTimeout(() => {
      setActivityIndices((prevIndices) => {
        const newIndices = [...prevIndices];
        const activityDescriptions = activitySlots[index]?.activities;
        if (activityDescriptions) {
          const nextIndex =
            (newIndices[index] + 1) % activityDescriptions.length;
          newIndices[index] = nextIndex;

          const slotId = activitySlots[index].id;
          const newActivityId = activityDescriptions[nextIndex].id;

          onUpdate?.((prev) => ({
            ...prev,
            activities: {
              ...prev.activities,
              [slotId]: newActivityId,
            },
          }));
        }
        return newIndices;
      });

      setActivityFades((prevFades) => {
        const newFades = [...prevFades];
        newFades[index] = false;
        return newFades;
      });
    }, 250);

    onCleanup(() => clearTimeout(dotsTimeout));
  };

  const content = (
    <div class="p-4">
      <div
        class={
          isEditable
            ? "relative rounded-[18px] border border-[#ffffff33] p-2"
            : ""
        }
      >
        <Show when={isEditable}>
          <img
            src={editAICircularIcon}
            class="absolute -right-[6px] -top-[6px]"
            alt="edit"
          />
        </Show>
        <img
          src={giftDetails()?.content.images.activityImages[0].imageUrl}
          class="m-auto h-[200px] w-[311px] rounded-xl"
          alt=""
        />
      </div>
      <div
        class={`my-4 ${isEditable ? "relative rounded-2xl border border-[#ffffff33] p-2" : ""}`}
        onClick={rotateTitle}
      >
        <Show when={isEditable}>
          <img
            src={editAICircularIcon}
            class="absolute -right-[6px] -top-[6px]"
            alt="edit"
          />
        </Show>
        <p
          class={`relative text-h3 text-baseTertiaryLight transition-opacity duration-500 ${
            titleFade() ? "opacity-0" : "opacity-100"
          }`}
        >
          {giftDetails()?.content.text.titles[titleIndex()]?.title}
        </p>
      </div>
      <div class="mb-4 flex items-center gap-1.5">
        <p class="text-nowrap text-[10px] font-medium uppercase text-[#A591A4]">
          Thoughtfully Created by {giftDetails()?.senderName}
        </p>
        <hr class="w-full border-t border-dashed border-[#A591A4]" />
      </div>
      <div class="flex flex-col gap-2.5">
        <For each={activitySlots}>
          {(activity, index) => {
            return (
              <div
                class={`flex items-center gap-2.5 ${
                  isEditable
                    ? "relative rounded-2xl border border-[#ffffff33] p-2"
                    : ""
                }`}
                onClick={() => rotateActivity(index())}
              >
                <Show when={isEditable}>
                  <div class="absolute -right-[6px] -top-[6px]">
                    <img src={editAICircularIcon} alt="edit" class="relative" />
                    <Show when={showDotsIndex() === index()}>
                      <div
                        class={`absolute -top-[8px] left-[8px] h-1.5 w-1.5 rounded-full border border-white ${
                          activityIndices()[index()] % 3 === 2 ? "bg-white" : ""
                        }`}
                      ></div>
                      <div
                        class={`absolute -top-[5px] left-0 h-1.5 w-1.5 rounded-full border border-white ${
                          activityIndices()[index()] % 3 === 1 ? "bg-white" : ""
                        }`}
                      ></div>
                      <div
                        class={`absolute -left-[6px] top-[2px] h-1.5 w-1.5 rounded-full border border-white ${
                          activityIndices()[index()] % 3 === 0 ? "bg-white" : ""
                        }`}
                      ></div>
                    </Show>
                  </div>
                </Show>
                <img
                  src={
                    giftDetails().brandDetails.find(
                      (brand) =>
                        brand.brandKey ===
                        activity.activities[activityIndices()[index()]].brandKey
                    )?.logoUrl
                  }
                  alt=""
                  class="h-10 w-10 rounded-full"
                />
                <p
                  class={`relative text-medium text-white transition-opacity duration-500 ${
                    activityFades()[index()] ? "opacity-0" : "opacity-100"
                  }`}
                >
                  {activity.activities[activityIndices()[index()]]?.description}
                </p>
              </div>
            );
          }}
        </For>
      </div>
    </div>
  );

  return (
    <Show when={onClick} fallback={content}>
      <button onClick={onClick} class="w-full text-left">
        {content}
      </button>
    </Show>
  );
}
