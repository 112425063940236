import { editAICircularIcon } from "~/assets/assets";
import { EditableField } from "./editable_field";
import { createEffect, createSignal, JSX, Show } from "solid-js";
import { CloseModalButton, useModal } from "../modal";
import { GiftCardDetails, GiftCardOccasion } from "~/server/types/pluto";

export function GiftCardFront({
  giftDetails,
  isEditable,
  onClick,
  onUpdate,
}: {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  onClick?: () => void;
  onUpdate?: (updatedTextFields: GiftCardOccasion) => void;
}) {
  const { openModal, closeModal } = useModal()!;

  const maxCharLimits = {
    recipientName: 100,
    occasionTitle: 100,
    wishMessage: 500,
  };

  const [textFields, setTextFields] = createSignal({
    recipientName: giftDetails()?.content.text.occasion.recipientName || "",
    occasionTitle: giftDetails()?.content.text.occasion.occasionTitle || "",
    wishMessage: giftDetails()?.content.text.occasion.wishMessage || "",
  });

  const editUserContent = (
    field: keyof ReturnType<typeof textFields>,
    title: string
  ) => {
    let tempText = textFields()[field];

    const handleInputChange: JSX.EventHandler<
      HTMLTextAreaElement,
      InputEvent
    > = (e) => {
      tempText = e.currentTarget.value;
    };

    const updateContent = () => {
      setTextFields((prev) => ({
        ...prev,
        [field]: tempText,
      }));
      onUpdate?.({ ...textFields(), [field]: tempText });
      closeModal();
    };

    openModal(
      () => (
        <div class="flex w-full flex-col gap-3 p-4">
          <div class="flex justify-between">
            <p class="text-h5 text-white">{title}</p>
            <CloseModalButton />
          </div>
          <div>
            <textarea
              rows="1"
              value={tempText}
              onInput={handleInputChange}
              class="text-sm no-scrollbar block max-h-40 w-full resize-none overflow-y-auto rounded-xl border border-basePrimaryLight bg-black p-3 pe-16 pl-5 text-white placeholder:text-baseSecondaryDark focus:border-baseTertiaryLight focus:ring-baseTertiaryLight disabled:pointer-events-none"
              placeholder={title}
            />
            <div class="mt-0.5 text-end text-[10px] font-semibold text-textNormal">
              {tempText.length}/{maxCharLimits[field]}
            </div>
          </div>
          <button
            class="flex h-11 w-full items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
            onClick={updateContent}
          >
            <p class="text-buttonMedium font-bold text-textDark">Done</p>
          </button>
        </div>
      ),
      "dark"
    );
  };

  createEffect(() => {
    if (giftDetails()?.content.text.occasion) {
      setTextFields({
        recipientName: giftDetails().content.text.occasion.recipientName || "",
        occasionTitle: giftDetails().content.text.occasion.occasionTitle || "",
        wishMessage: giftDetails().content.text.occasion.wishMessage || "",
      });
    }
  });

  const content = (
    <div class="p-4">
      <div
        class={
          isEditable
            ? "relative mb-5 rounded-[18px] border border-[#ffffff33] p-2"
            : ""
        }
      >
        <Show when={isEditable}>
          <img
            src={editAICircularIcon}
            class="absolute -right-[6px] -top-[6px]"
            alt="edit"
          />
        </Show>
        <img
          src={giftDetails()?.content.images.occasionImages[0].imageUrl}
          class={`m-auto h-[311px] w-[300px] rounded-[10px] ${isEditable ? "" : "mb-5"}`}
          alt=""
        />
      </div>
      <div
        class={`flex flex-col text-center text-white ${isEditable ? "gap-3" : "gap-1"}`}
      >
        <Show
          when={isEditable}
          fallback={
            <>
              <p class="text-medium">{textFields().recipientName}</p>
              <h1 class="text-h3">{textFields().occasionTitle}</h1>
              <p class="text-medium text-[#C6A6CC]">
                {textFields().wishMessage}
              </p>
            </>
          }
        >
          <>
            <EditableField
              value={() => textFields().recipientName}
              classNames="text-medium"
              onClick={() =>
                editUserContent("recipientName", "Receiver's name")
              }
            />
            <EditableField
              value={() => textFields().occasionTitle}
              classNames="text-h3"
              onClick={() => editUserContent("occasionTitle", "Edit Message")}
            />
            <EditableField
              value={() => textFields().wishMessage}
              classNames="text-medium text-[#C6A6CC]"
              onClick={() => editUserContent("wishMessage", "Wish message")}
            />
          </>
        </Show>
      </div>
    </div>
  );

  return (
    <Show when={onClick} fallback={content}>
      <button onClick={onClick} class="w-full text-left">
        {content}
      </button>
    </Show>
  );
}
